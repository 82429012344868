import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	root: {
		width: "100%",
	},
	container: {
		maxHeight: 440,
	},
});
