import _ from "lodash";
const config = {
	all: {
		env: process.env.REACT_APP_STAGE || "develop",
	},
	develop: {
		apiBase: "https://dev-api.megacash.io",
	},
	release: {
		apiBase: "",
	},
	prod: {
		apiBase: "https://api.megacash.io",
	},
};

export default _.merge(config.all, config[config.all.env]);
