import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Paper,
	Box,
	Typography,
	IconButton,
	Icon,
	Grid,
} from "@material-ui/core";
import Loader from "~/components/Loader";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";

import { purchaseBorletteTicket } from "~/redux/helpers/borlette";

const useStyles = makeStyles((theme) => ({
	upcomingLotteryContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "2rem 0.5rem",
		minHeight: "15rem",
	},
	countDownActive: {
		minHeight: "25rem",
	},
	fullHeight: {
		height: "100%",
	},
	headerIcon: {
		fontSize: "1.5rem",
		width: "1.25em",
	},
	removeItem: {
		color: theme.palette.error.main,
	},
	addItem: {
		color: theme.palette.success.main,
	},
	icon: {
		fontSize: "1.5rem",
		cursor: "pointer",
	},
}));

export default ({ pending, borlette }) => {
	const classes = useStyles();
	const history = useHistory();
	const [pendingPurchase, setPendingPurchase] = useState(false);
	const validationSchema = useRef(
		yup.array().of(
			yup.object({
				numberPlayed: yup
					.string()
					.test(
						"numberValidator",
						"This number is invalid",
						(value) => {
							let borletteRegex = /^([0-9]{1,4})$/;
							let marriageRegex = /^([0-9]{2,2})([x]{1,1}([0-9]{2,2}))$/;
							if (
								borletteRegex.test(value) ||
								marriageRegex.test(value)
							) {
								return true;
							}
							return false;
						}
					)
					.required("Number is required"),
				amountPlayed: yup
					.number()
					.typeError("Amount must be a number")
					.required("Amount is required"),
			})
		)
	);

	const removeItem = (index) => {
		formik.setValues(formik.values.filter((_item, i) => i !== index));
	};

	const addItem = () => {
		formik.setValues([
			...formik.values,
			{
				numberPlayed: "",
				amountPlayed: "",
			},
		]);
	};

	const handleBorletteTicketPurchase = (values) => {
		setPendingPurchase(true);
		purchaseBorletteTicket(borlette._id, {
			numbers: formik.values.map((item) => ({
				numberPlayed: item.numberPlayed,
				amountPlayed: item.amountPlayed * 100,
			})),
		})
			.then((response) => history.push(`/print/${response.borletteTicket._id}`))
			.catch((error) => console.log(error))
			.finally(() => setPendingPurchase(false));
	};

	const formik = useFormik({
		initialValues: [
			{
				numberPlayed: "",
				amountPlayed: "",
			},
		],
		validationSchema: validationSchema.current,
		onSubmit: handleBorletteTicketPurchase,
	});

	if (pending) {
		return <Loader />;
	}
	
	return (
		<Paper>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Box p={2} className={clsx(classes.upcomingLotteryContainer)}>
					<Grid
						container
						spacing={1}
						justify="center"
						alignItems="center"
					>
						<Grid item xs={12}>
							<Typography variant="h3">Select Numbers</Typography>
						</Grid>
						{formik.values.map((_selectedNumber, index) => (
							<>
								<Grid item xs={5}>
									<TextField
										type="text"
										label="Number"
										name={`${index}.numberPlayed`}
										value={
											formik.values[index].numberPlayed
										}
										onChange={formik.handleChange}
										error={
											formik.touched[index] &&
											formik.touched[index]
												.numberPlayed &&
											Boolean(
												formik.errors[index] &&
													formik.errors[index]
														.numberPlayed
											)
										}
										helperText={
											formik.touched[index] &&
											formik.touched[index]
												.numberPlayed &&
											formik.errors[index] &&
											formik.errors[index].numberPlayed
										}
									/>
								</Grid>
								<Grid item xs={5}>
									<TextField
										type="text"
										label="Amount"
										name={`${index}.amountPlayed`}
										value={
											formik.values[index].amountPlayed
										}
										onChange={formik.handleChange}
										error={
											formik.touched[index] &&
											formik.touched[index]
												.amountPlayed &&
											Boolean(
												formik.errors[index] &&
													formik.errors[index]
														.amountPlayed
											)
										}
										helperText={
											formik.touched[index] &&
											formik.touched[index]
												.amountPlayed &&
											formik.errors[index] &&
											formik.errors[index].amountPlayed
										}
									/>
								</Grid>
								<Grid item xs={1}>
									{formik.values.length > 1 && (
										<i
											onClick={() => removeItem(index)}
											className={clsx(
												classes.removeItem,
												classes.icon,
												"fa fa-trash"
											)}
										/>
									)}
								</Grid>
								<Grid item xs={1}>
									{index === formik.values.length - 1 && (
										<i
											onClick={addItem}
											className={clsx(
												classes.addItem,
												classes.icon,
												"fa fa-plus"
											)}
										/>
									)}
								</Grid>
							</>
						))}
					</Grid>
					<Grid
						container
						spacing={1}
						justify="center"
						alignItems="center"
					>
						<Grid item xs={8}>
							<Box my={5}>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									pending={pendingPurchase}
									fullWidth={200}
								>
									Purchase
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Paper>
	);
};
