import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Paper,
	Typography,
	Box,
	Icon,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import Button from "~/components/Forms/Button";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "1rem",
	},
	noDataText: {
		color: theme.palette.text.disabled,
		margin: "2rem",
		textAlign: "center",
	},
}));

export default function ({ text, actionTitle, onAction }) {
	const classes = useStyles();

	return (
		<Box p={[10, 5]} className={classes.container}>
			<Typography variant="h1" className={classes.noDataText}>
				{text}
			</Typography>
			{onAction && (
				<Button onClick={onAction} color="primary" variant="contained">
					{actionTitle}
				</Button>
			)}
		</Box>
	);
}
