import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
	},
	sectionHeader: {
		marginTop: "2rem",
		paddingBottom: "0.5rem",
		borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1.5rem",
	},
	delete: {
		color: theme.palette.error.main,
	},
	tableFooter: {
		padding: "1rem 0rem"
	},
}));
