import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import Table from "~/components/Table";
import Loader from "~/components/Loader";
import { fetchTicket } from "~/redux/helpers/borlette"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	contentWrapper: {
		padding: "0rem 1rem",
	},
	sectionHeader: {
		marginTop: "2rem",
		paddingBottom: "0.5rem",
		borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1.5rem",
	},
	delete: {
		color: theme.palette.error.main,
	},
	tableFooter: {
		padding: "1rem 0rem",
	},
}));

export default function () {
	const classes = useStyles();
	const { ticketId } = useParams();
	const history = useHistory();
	const [pending, setPending] = useState(true);
	const [ticket, setTicket] = useState({})
	const columns = [
		{ id: "numberPlayed", label: "Number" },
		{ id: "amountPlayed", label: "Amount" },
		{ id: "amountWon", label: "Rewards" },
	];
	const rows = (ticket.numbers || []).map((item) => ({
		amountPlayed: item.amountPlayed,
		amountWon: item.amountWon || 0,
		numberPlayed: item.numberPlayed,
	}));

	const handlePrintTicket = () => history.push(`/print/${ticketId}`);

	useEffect(() => {
		fetchTicket(ticketId)
			.then(response => {
				setTicket(response.borletteTicket);
			})
			.catch(error => console.log(error))
		.finally(() => {
			setPending(false)
		})
	}, [])

	const renderTotal = () => (
		<Box className={classes.tableFooter}>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="subtitle2">Total Amount Paid</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2">
						{ticket.totalAmountPlayed}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="subtitle2">Total Rewards</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="subtitle2">
						{ticket.totalAmountWon || 0}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);

	if (pending) {
		return <Loader />
	}
	return (
		<>
			<Header />
			<PageTitle
				title="Ticket Details"
				showAction={true}
				actionTitle="Print Ticket"
				onAction={handlePrintTicket}
			/>
			<Box className={classes.contentWrapper}>
				<Box className={classes.contentContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Table
								columns={columns}
								rows={rows}
								footer={renderTotal()}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}
