import React, { useState } from "react";
import moment from "moment";
import { TextField } from "@material-ui/core";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import {
	LocalizationProvider,
	DateRangePicker,
	DateRange,
	DateRangeDelimiter,
} from "@material-ui/pickers";

export default function DaterangePicker({ startDate, endDate, onChange }) {
	const [value, setValue] = useState([startDate, endDate]);
	return (
		<LocalizationProvider dateAdapter={MomentUtils}>
			<DateRangePicker
				startText="Start Date"
				endText="End Date"
				value={value}
				onChange={(newValue) => {
					const startDate = moment(newValue[0])
						.startOf("day")
						.valueOf();
					const endDate = moment(newValue[1]).endOf("day").valueOf();
					setValue([startDate, endDate]);
					onChange([startDate, endDate]);
				}}
				renderInput={(startProps, endProps) => (
					<>
						<TextField {...startProps} helperText="" />
						<DateRangeDelimiter> to </DateRangeDelimiter>
						<TextField {...endProps} helperText="" />
					</>
				)}
			/>
		</LocalizationProvider>
	);
}
