import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const getUpcomingBorlette = async () => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/upcoming`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorletteList = async () => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorlette = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const purchaseBorletteTicket = async (borletteId, data) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/purchase/${borletteId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(data),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const deleteBorletteTicket = async (ticketId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/ticket/delete/${ticketId}`,
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorletteTicket = async (borletteId, limit, offset) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/tickets/${
				borletteId ? borletteId : ""
			}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				limit,
				offset,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchTicket = async (ticketId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/ticket/${ticketId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const cashoutBorletteTicket = async (ticketId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/ticket/cashout/${ticketId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};