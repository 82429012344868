import React from "react";
import { Tabs as MUITabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props) =>
	makeStyles((theme) => ({
		indicator: {
			display: "flex",
			justifyContent: "center",
			backgroundColor: "transparent",
			height: 3,
			"& > span": {
				maxWidth: "100%",
				width: "100%",
				backgroundColor: theme.palette.primary.main,
			},
		},
	}))();

export default (props) => {
	const { orientation } = props;
	const classes = useStyles({ orientation });
	return (
		<MUITabs
			{...props}
			TabIndicatorProps={{ children: <span /> }}
			classes={{
				indicator: classes.indicator,
			}}
		/>
	);
}