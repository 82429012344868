import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Box,
} from "@material-ui/core";
import Header from "~/modules/Header";
import Loader from "~/components/Loader";
import UpcomingLottery from "~/modules/UpcomingLottery";

import { getUpcomingBorlette } from "~/redux/helpers/borlette";

import useStyles from "./styles";

export default function Dashboard(props) {
	const classes = useStyles();
	const [countDown, setCountDown] = useState(-1);
	const [borlette, setBorlette] = useState({});
	const [pending, setPending] = useState(true);

	useEffect(() => {
		getUpcomingBorlette()
			.then(response => {
				setBorlette(response.borlette)
				setCountDown(response.countDown);
			})
			.catch(error => console.log(error))
			.finally(() => setPending(false))
	}, [])
	const data = [
		{
			title: "Sales",
			value: "$ 568,000",
			icon: "fa-bar-chart",
		},
		{
			title: "Awards",
			value: "$ 260,000",
			icon: "fa-trophy",
		},
		{
			title: "Commission",
			value: "$ 85,200",
			icon: "fa-scissors",
		},
		{
			title: "Balance",
			value: "$ 222,800",
			icon: "fa-bank",
		},
	];
	return (
		<>
			<Header />
			<Grid container justify="center" spacing={5}>
				<Grid item xs={10} sm={6} md={4}>
					<Box className={classes.pageTitleWrapper} py={2}>
						<Typography variant="h1">Upcoming Lottery</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container justify="center">
				<Grid item xs={10} sm={6} md={4}>
					<UpcomingLottery pending={pending} borlette={borlette} countDown={countDown} />
				</Grid>
			</Grid>
		</>
	);
}
