import React, { useState, useEffect } from "react";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import LotteryList from "~/modules/LotteryList";

import useStyles from "./styles";

export default () => {
	const classes = useStyles();
	return (
		<>
			<Header />
			<PageTitle
				title="Lotteries"
				activeTabIndex={0}
				onTabChange={() => null}
				showAction={false}
			/>
			<Box className={classes.contentWrapper}>
				<LotteryList />
			</Box>
		</>
	);
};
