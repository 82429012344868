import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	sidebar: {
		position: "fixed",
		width: "200px",
		display: "flex",
		flexDirection: "column",
		height: "100vh",
		// backgroundColor: theme.palette.primary.main,
		backgroundImage: `linear-gradient(
			to bottom, 
			${theme.palette.primary.main}, 
			${theme.palette.primary.light}, 
			${theme.palette.primary.main}
		)`,
		left: "-200px",
		transition: "all 0.25s",
		[theme.breakpoints.up("sm")]: {
			left: "0px",
		},
	},
	sidebarDisplayed: {
		left: "0px",
	},
	toggleSidebar: {
		position: "absolute",
	},
	navContainer: {
		marginTop: "50px",
	},
	logoContainer: {
		display: "flex",
		justifyContent: "center",
		padding: "15px",
	},
	logoMain: {
		maxWidth: "120px",
	},
	menuItem: {
		display: "block",
		fontSize: "16px",
		fontWeight: "500",
		color: theme.palette.primary.contrastText,
		padding: "15px 10px",
		cursor: "pointer",
		border: "none",
		outline: "none",
	},
	subMenuItem: {
		display: "block",
		padding: "15px 20px",
		fontSize: "14px",
		fontWeight: "300",
		color: "#3b5998",
		borderBottom: "1px solid #cecece",
	},
	activeItem: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.secondary.contrastText,
	},
}));
