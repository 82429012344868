import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { Box, Icon, MenuItem, Grid, Menu, Typography } from "@material-ui/core";

import { toggleSidebar } from "~/redux/actions/appConfig";
import TextField from "~/components/Forms/TextField";

import { logout } from "~/redux/actions/user";

import useStyles from "./styles";

export default function Header() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const sidebarDisplayed = useSelector(
		(state) => state.appConfig.sidebarDisplayed
	);
	const handleSideBarToggle = () => {
		dispatch(toggleSidebar(!sidebarDisplayed));
	};

	const handleToggle = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogout = () => {
		dispatch(logout());
		handleClose();
	};
	return (
		<Box className={classes.header}>
			<Box
				className={classes.toggleContainer}
				onClick={handleSideBarToggle}
			>
				<Icon
					className={clsx(
						"fa fa-2x",
						sidebarDisplayed ? "fa-times" : "fa-align-justify"
					)}
				/>
			</Box>
			<Box className={classes.headerNavigation}>
				<Grid
					container
					spacing={5}
					justify="flex-end"
					alignItems="center"
				>
					<Grid item xs={10} sm={6}>
						<Box className={classes.rightNavDropdownContainer}>
							<Box className="LeftNav">
								<Box className={classes.headerMenuList}>
									<Typography variant="body1" color="primary">
										Welcome, {user.firstName}
									</Typography>
									<Box
										className={classes.dropdownMenu}
										onClick={handleToggle}
									>
										<i className="fa fa-2x fa-user-circle" />
										<i className="fa fa-x fa-chevron-down" />
									</Box>
								</Box>
								<Menu
									elevation={0}
									getContentAnchorEl={null}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<MenuItem onClick={handleLogout}>
										Logout
									</MenuItem>
								</Menu>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}