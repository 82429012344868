import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const userInfo = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/me`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		const responseBody = await response.data;
		dispatch({
			type: "LOGIN_SUCCESS",
			payload: responseBody.user,
		});
		return true;
	} catch (error) {
		dispatch({
			type: "LOGIN_FAILED",
			payload: {
				error: error.message || "An error has occured.",
			},
		});
		return false;
	}
};

export const login = (credentials) => async (dispatch) => {
	try {
		const response = await axios({
			url: `${config.apiBase}/agent/login`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: JSON.stringify(credentials),
		});
		const { user, accessToken } = await response.data;
		window.localStorage.setItem("@accessToken", accessToken);
		dispatch({
			type: "LOGIN_SUCCESS",
			payload: user,
		});
		return true;
	} catch (error) {
		dispatch({
			type: "LOGIN_FAILED",
			payload: {
				error: error.message || "An error has occured.",
			},
		});
		return false;
	}
};

export const logout = () => {
	window.localStorage.removeItem("@accessToken");
	return {
		type: "LOGOUT_SUCCESS",
		payload: {},
	};
};
