import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	pageTitleWrapper: {
		position: 'relative'
	},
	titleWrapper: {
		padding: "0rem 1rem",
		alignItems:"center",
		zIndex: 999,
		background: theme.palette.background.header,
		marginBottom: "2.5rem",
		boxShadow:
			"0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
		[theme.breakpoints.up("sm")]: {
		},
	},
	title: {
		textTransform: "capitalize",
		color: theme.palette.primary.light,
		textAlign: "center",
		padding: "1rem 0rem",
		[theme.breakpoints.up("sm")]: {
			textAlign: "left",
		},
	},
	fixPositioned: {
		position: "absolute",
		right: "1rem",
		bottom: "-1.5rem",
		zIndex: 999
	},
}));
