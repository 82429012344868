import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import Accordion from "~/components/Accordion";
import Logo from "~/assets/images/logo.png";

import useStyles from "./styles";

export default function Sidebar(props) {
	const { navigation } = props;
	const classes = useStyles();
	const sidebarDisplayed = useSelector(
		(state) => state.appConfig.sidebarDisplayed
	);
	return (
		<Box
			className={clsx(
				classes.sidebar,
				sidebarDisplayed ? classes.sidebarDisplayed : ""
			)}
		>
			<Box className={classes.logoContainer}>
				<Link to="/">
					<img
						src={Logo}
						alt="app-logo"
						className={classes.logoMain}
					/>
				</Link>
			</Box>
			<ul className={classes.navContainer}>
				{navigation.map((navItem, itemIndes) =>
					navItem.items.length === 0 ? (
						<li key={`nav-item-${itemIndes}`}>
							<Link
								className={`${classes.menuItem}`}
								to={navItem.link}
							>
								<Typography variant="body1">
									{navItem.title}
								</Typography>
							</Link>
						</li>
					) : (
						<Accordion
							key={`nav-item-${itemIndes}`}
							initialExpanded={false}
							header={(e) => (
								<Box className={classes.menuItem}>
									<Typography variant="body1">
										{navItem.title}
									</Typography>
								</Box>
							)}
							content={(e) => (
								<ul className={classes.submenu}>
									{navItem.items.map(
										(subItem, subItemIndex) => (
											<li
												key={`nav-item-${itemIndes}-subitem-${subItemIndex}`}
											>
												<Link
													className={
														classes.subMenuItem
													}
													to={subItem.link}
												>
													<Typography variant="body2">
														{subItem.title}
													</Typography>
												</Link>
											</li>
										)
									)}
								</ul>
							)}
						/>
					)
				)}
			</ul>
		</Box>
	);
}
