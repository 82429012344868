import React from "react";
import {
	makeStyles,
	TextField as MUITextField,
	Tooltip,
	InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		//height: "70px",
	},
	textField: {
	},

	cssLabel: {
		color: theme.palette.primary.main,
	},
	cssDisabled: {
		"&::before": {
			borderBottomStyle: "solid",
		},
	},
	cssFilledInput: {
		"&::before": {
			borderBottom: `1px solid ${theme.palette.primary.light}`,
		},
		"&$cssDisabled": {
			"&::before": {
				borderBottom: `1px solid rgba(0,0,0,0.42)`,
			},
		},
		"&$cssFocused": {
			//borderColor: `${theme.palette.primary.main} !important`,
		},
	},

	cssFocused: {},
}));

export default (props) => {
	const {
		id,
		name,
		label,
		value,
		type,
		required,
		select,
		onChange,
		onBlur,
		helperText,
		error,
		tooltipProps,
		disabled,
		children,
		inputProps,
		...restProps
	} = props;

	const classes = useStyles();

	// const info = tooltipProps && (
	// 	<Tooltip title={tooltipProps.title} arrow placement="right">
	// 		{tooltipProps.icon || <InfoOutlinedIcon />}
	// 	</Tooltip>
	// );

	return (
		<div className={classes.root}>
			<MUITextField
				select={select ? true : false}
				name={name}
				id={id}
				label={label}
				type={type}
				variant="outlined"
				autoComplete="off"
				autoFocus={false}
				value={value}
				required={required ? true : false}
				className={classes.textField}
				inputProps={{
					...inputProps,
				}}
				InputLabelProps={{
					classes: {
						root: classes.cssLabel,
						focused: classes.cssFocused,
					},
				}}
				InputProps={{
					// endAdornment: tooltipProps ? (
					// 	<InputAdornment position="end">{info}</InputAdornment>
					// ) : null,
					classes: {
						root: classes.cssFilledInput,
						focused: classes.cssFocused,
						disabled: classes.cssDisabled,
					},
				}}
				onChange={onChange}
				onBlur={onBlur}
				error={error}
				helperText={helperText}
				autoFocus={false}
				autoComplete="off"
				disabled={disabled}
				fullWidth
				{...restProps}
			>
				{select && children}
			</MUITextField>
		</div>
	);
};
