import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Typography, Icon } from "@material-ui/core";
import Loader from "~/components/Loader";
import Button from "~/components/Forms/Button";
import DateCountdown from "~/components/DateCountdown";

const useStyles = makeStyles((theme) => ({
	upcomingLotteryContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "2rem 0.5rem",
		minHeight: "15rem",
	},
	noData: {
		color: theme.palette.text.disabled,
	},
	countDownActive: {
		minHeight: "25rem",
	},
	fullHeight: {
		height: "100%",
	},
	headerIcon: {
		fontSize: "1.5rem",
		width: "1.25em",
	},
	fixPositioned: {
		position: "absolute",
		right: "1rem",
		cursor: "pointer",
	},
}));

export default ({ pending, borlette, countDown }) => {
	const classes = useStyles();
	const history = useHistory();
	const [borletteCountDown, setBorletteCountDown] = useState(countDown);

	useEffect(() => {
		setBorletteCountDown(countDown);
	}, [countDown]);

	
	if (pending) {
		return <Loader />;
	}
	
	return (
		<Paper>
			<Box
				p={2}
				className={clsx(
					classes.upcomingLotteryContainer,
					countDown >= 0 ? classes.countDownActive : ""
				)}
			>
				{countDown >= 0 ? (
					<>
						<Typography variant="h3">{borlette.name}</Typography>
						{/* <Typography variant="h3" color="primary">
							Reward
						</Typography>
						<Typography variant="h1" color="primary">
							{borlette.jackpotAmount}
						</Typography> */}
						<DateCountdown
							mostSignificantFigure="day"
							countDown={borletteCountDown}
							callback={() => setBorletteCountDown(-1)}
						/>
						<Box px={3} py={5}>
							<Button
								onClick={(e) =>
									history.push(`/purchase/${borlette._id}`)
								}
								color="primary"
								variant="contained"
								fullWidth
							>
								Purchase Ticket
							</Button>
						</Box>
					</>
				) : (
					<Typography variant="h2" className={classes.noData}>
						No Upcoming Lotteries Found.
					</Typography>
				)}
			</Box>
		</Paper>
	);
};
