import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { Box, Snackbar } from "@material-ui/core";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import { toggleSidebar, updateError } from "~/redux/actions/appConfig";
import { userInfo } from "~/redux/actions/user";

import Sidebar from "~/modules/Sidebar";
import Login from "~/views/Login";
import Dashboard from "~/views/Dashboard";
import Lotteries from "~/views/Lotteries";
import Tickets from "~/views/Tickets";
import PrintTicket from "~/views/PrintTicket";
import LotteryDetails from "~/views/LotteryDetails";
import PurchaseTicket from "~/views/PurchaseTicket";
import Transactions from "~/views/Transactions";
import CashoutTicket from "~/views/CashoutTicket";
import TicketDetails from "./views/TicketDetails";

import "./App.css";

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const themeConfig = (theme) => {
	return {
		palette: {
			...theme.palette,
		},
		typography: {
			...theme.typography,
		},
		overrides: {
			MuiInputBase: {
				root: {
					fontSize: `${theme.typography.baseFontSize}rem`,
				},
			},
			MuiTextField: {
				root: {
					"&:focus": {
						outline: "none",
					},
				},
			},
			MuiOutlinedInput: {
				root: {
					"&$focused $notchedOutline": {
						borderWidth: 1,
					},
				},
				input: {
					padding: "1rem 1rem",
				},
			},
			MuiIcon: {
				root: {
					fontSize: "1.25rem",
				},
			},
			// MuiSelect: {
			// 	outlined: {
			// 		padding: ".5rem 1rem",
			// 		fontSize: "0.875rem",
			// 	},
			// },
			// MuiButton: {
			// 	root: {
			// 		borderRadius: 0,
			// 		padding: ".5rem 2rem",
			// 	},
			// 	label: {
			// 		fontSize: `${theme.typography.baseFontSize}rem`,
			// 	},
			// },
			// MuiDialog: {
			// 	paperScrollPaper: {
			// 		borderRadius: 0,
			// 	},
			// },
			MuiPaper: {
				elevation1: {
					// boxShadow: "-3px 2px 2px 0 rgba(119,151,178,0.17)"
					boxShadow: "0 0 10px 0 rgba(51,60,75,0.1);",
				},
			},
			// MuiDialogTitle: {
			// 	root: {
			// 		padding: 0,
			// 	},
			// },
			MuiListItem: {
				root: {
					paddingTop: "0.563rem",
					paddingBottom: "0.438rem",
				},
				button: {
					borderRadius: 5,
				},
				gutters: {
					paddingLeft: "0.75rem",
					paddingRight: "0.75rem",
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: "3.125rem",
				},
			},
			MuiSvgIcon: {
				root: {
					cursor: "pointer",
				},
			},
			PrivateNotchedOutline: {
				root: {
					borderRadius: 5,
				},
			},
		},
	};
};

export default function App() {
	const appConfig = useSelector((state) => state.appConfig);
	const { isLoggedIn } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const location = useLocation();

	const handleClose = () => {
		dispatch(updateError(null));
	};

	useEffect(() => {
		dispatch(userInfo());
	}, []);

	useEffect(() => {
		dispatch(toggleSidebar(false));
	}, [location]);

	if (isLoggedIn === null) {
		return null;
	}

	return (
		<MuiThemeProvider
			theme={createMuiTheme(
				appConfig.layout ? themeConfig(appConfig.layout.theme) : {}
			)}
		>
			{isLoggedIn ? (
				<>
					<Sidebar navigation={appConfig.navigation.sidebar} />
					<Box
						className={clsx(
							"hasSidebar",
							appConfig.sidebarDisplayed ? "sidebarDisplayed" : ""
						)}
					>
						<Switch>
							<Route path="/dashboard">
								<Dashboard />
							</Route>
							<Route path="/lotteries">
								<Lotteries />
							</Route>
							<Route path="/tickets">
								<Tickets />
							</Route>
							<Route path="/ticket/:ticketId">
								<TicketDetails />
							</Route>
							<Route path="/print/:ticketId">
								<PrintTicket />
							</Route>
							<Route path="/lottery/:borletteId">
								<LotteryDetails />
							</Route>
							<Route path="/transactions">
								<Transactions />
							</Route>
							<Route path="/purchase/:lotteryId">
								<PurchaseTicket />
							</Route>
							<Route path="/cashout">
								<CashoutTicket />
							</Route>
							<Route path="*">
								<Redirect to="/dashboard" />
							</Route>
						</Switch>
					</Box>
				</>
			) : (
				<Switch>
					<Route exact path="/">
						<Login />
					</Route>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			)}
			<Snackbar
				open={Boolean(appConfig.errorMessage)}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity="error">
					{appConfig.errorMessage}
				</Alert>
			</Snackbar>
		</MuiThemeProvider>
	);
}
