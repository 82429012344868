import React, { useEffect, useState, useRef } from "react";
import { S3 } from "@aws-sdk/client-s3";
import moment from "moment";
import * as htmlToImage from "html-to-image";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Grid,
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from "@material-ui/core";
import TextField from "~/components/Forms/TextField";
import Button from "~/components/Forms/Button";
import { useReactToPrint } from "react-to-print";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import Table from "~/components/Table";
import Loader from "~/components/Loader";
import { fetchTicket } from "~/redux/helpers/borlette";
import { uploadBase64ImageToS3 } from "~/redux/helpers/upload";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	contentWrapper: {
		padding: "1rem 2rem",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		maxWidth: "500px",
		margin: "0 auto",
	},
	printContainer: {
		width: "100%",
		padding: "1rem 1rem",
	},
	center: {
		display: "flex",
		justifyContent: "center",
	},
	sectionHeader: {
		marginTop: "2rem",
		paddingBottom: "0.5rem",
		borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
	},
	buttonContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1.5rem",
	},
	delete: {
		color: theme.palette.error.main,
	},
	tableFooter: {
		padding: "1rem 0.5rem",
	},
	dialog: {
		width: "500px",
	},
}));

export default function () {
	const classes = useStyles();
	const componentRef = useRef();
	const { ticketId } = useParams();
	const [pending, setPending] = useState(true);
	const [userPhoneNumber, setUserPhoneNumber] = useState(null);
	const [isSendMessageDialogOpen, setIsSendMessageDialogOpen] = useState(
		false
	);
	const [uploadProgress, setUploadProgress] = useState(false);
	const [ticket, setTicket] = useState({});
	const columns = [
		{ id: "numberPlayed", label: "Number" },
		{ id: "amountPlayed", label: "Amount" },
	];
	const rows = (ticket.numbers || []).map((item) => ({
		amountPlayed: item.amountPlayed / 100,
		amountWon: (item.amountWon || 0) / 100,
		numberPlayed: item.numberPlayed,
	}));
	const objectToGetParams = (object) => {
		const params = Object.entries(object)
			.filter(([, value]) => value !== undefined && value !== null)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(
						String(value)
					)}`
			);

		return params.length > 0 ? `?${params.join("&")}` : "";
	};
	const closeDialog = () => {
		setUserPhoneNumber(null);
		setIsSendMessageDialogOpen(false);
	}
	const isMobileOrTablet = () => {
		return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
	};
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const sendMessage = () => {
		setUploadProgress(true);
		setIsSendMessageDialogOpen(null);
		const acl = "public-read";
		const contentType = "image/png";
		htmlToImage
			// .toBlob(node)
			.toPng(componentRef.current)
			.then(function (dataUrl) {
				return uploadBase64ImageToS3(dataUrl, contentType, acl);
			})
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				const whatsAppUrl =
					"https://wa.me/" +
					// (isMobileOrTablet() ? "api" : "web") +
					// ".whatsapp.com/send" +
					ticket.franchise.country.dialCode +
					userPhoneNumber +
					objectToGetParams({
						text: response.imageUrl,
					});
				console.log("whatsAppUrl => ", whatsAppUrl);
				window.open(
					whatsAppUrl,
					"sharer",
					"toolbar=0,status=0,width=650,height=750"
				);
			})
			.catch(function (error) {
				console.error("oops, something went wrong!", error);
			})
			.finally(() => {
				setUploadProgress(false);
				closeDialog();
			});
	};

	useEffect(() => {
		fetchTicket(ticketId)
			.then((response) => {
				setTicket(response.borletteTicket);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
				// handlePrint();
			});
	}, []);

	const renderTotal = () => (
		<Box className={classes.tableFooter}>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={6}>
					<Typography variant="h2">Total Amount Paid</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h2">
						{ticket.totalAmountPlayed / 100}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);

	if (pending) {
		return <Loader />;
	}
	return (
		<>
			<Box className={classes.contentWrapper}>
				<Box ref={componentRef} className={classes.printContainer}>
					<Box py={2}>
						<Box py={1} className={classes.center}>
							<Typography variant="h1">
								{ticket.franchise.franchiseName}
							</Typography>
						</Box>
						<Box className={classes.center}>
							<Typography variant="body2">
								Email: {ticket.franchise.email}
							</Typography>
						</Box>
						<Box className={classes.center}>
							<Typography variant="body2">
								Phone Number: {ticket.franchise.phoneNumber}
							</Typography>
						</Box>
					</Box>
					<Box py={2} className={classes.center}>
						<Typography variant="h1">Payment Slip</Typography>
					</Box>
					<Box py={2}>
						{/* <Grid container spacing={2}>
							<Grid item xs={4}>
								<Typography variant="h3">Franchise:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="h2">
									{ticket.franchise.franchiseName}
								</Typography>
							</Grid>
						</Grid> */}
						<Grid container spacing={4}>
							<Grid item xs={4}>
								<Typography variant="h3">
									Lottery Name:
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="h2">
									{ticket.borlette.name}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={4}>
								<Typography variant="h3">Ticket ID:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="h2">{ticketId}</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={4}>
								<Typography variant="h3">
									Purchased On:
								</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography variant="h2">
									{moment(ticket.createdAt).format(
										"DD MMM, YYYY hh:mm A"
									)}
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Table
								columns={columns}
								rows={rows}
								footer={renderTotal()}
							/>
						</Grid>
					</Grid>
				</Box>
				<box className={classes.printContainer}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Box py={5}>
								<Button
									onClick={handlePrint}
									color="primary"
									variant="contained"
									fullWidth
								>
									Print Ticket
								</Button>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box py={5}>
								<Button
									onClick={(e) =>
										setIsSendMessageDialogOpen(true)
									}
									color="primary"
									variant="contained"
									pending={uploadProgress}
									fullWidth
								>
									Send via WhatsApp
								</Button>
							</Box>
						</Grid>
					</Grid>
				</box>
			</Box>
			<Dialog
				open={isSendMessageDialogOpen}
				onClose={closeDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Enter User's Phone Number
				</DialogTitle>
				<DialogContent className={classes.dialog}>
					<TextField
						autoFocus
						margin="dense"
						label="Enter User's Phone Number"
						onChange={(e) => setUserPhoneNumber(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={closeDialog}
						color="primary"
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={sendMessage}
						color="primary"
						variant="contained"
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
