import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "1rem",
		zIndex: 99,
		background: theme.palette.background.header,
		boxShadow:
			"0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
		[theme.breakpoints.up("sm")]: {},
	},
	toggleContainer: {
		cursor: "pointer",
		[theme.breakpoints.up("sm")]: {
			visibility: "hidden",
		},
	},
	headerNavigation: {
		color: theme.palette.primary.main,
		width: "55%",
		marginRight: "10px",
		[theme.breakpoints.up("sm")]: {
			width: "35%",
		},
	},
	rightNavDropdownContainer: {
		color: theme.palette.primary.main,
		marginRight: "10px",
	},
	headerMenuList: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	dropdownMenu: {
		width: "25%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer"
	},
}));
