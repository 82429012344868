import React from "react";
import { Tab as MUITab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = ({ alignItems }) =>
	makeStyles((theme) => ({
		root: {
			textTransform: "none",
			minWidth: 50,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: theme.spacing(4),
			padding: 1,
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"& > span": {
				alignItems: alignItems ? alignItems : "center",
			},
			"&:hover": {
				color: theme.palette.primary.light,
				opacity: 1,
			},
			"&$selected": {
				color: theme.palette.primary.light,
				fontWeight: theme.typography.fontWeightMedium,
			},
			"&:focus": {
				color: theme.palette.primary.light,
			},
		},
		selected: {},
	}))();

export default (props) => {
	const { alignItems } = props;
	const classes = useStyles({ alignItems });
	return (
		<MUITab
			disableRipple
			{...props}
			classes={{
				root: classes.root,
				selected: classes.selected,
			}}
		/>
	);
};