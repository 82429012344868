import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import CashoutBorletteTicket from "~/modules/CashoutBorletteTicket";

import useStyles from "./styles";

export default (props) => {
	const { type } = useParams();
	const classes = useStyles();
	return (
		<>
			<Header />
			<PageTitle title="Cashout Borlette Ticket" showAction={false} />
			<Box className={classes.contentWrapper}>
				<CashoutBorletteTicket />
			</Box>
		</>
	);
}
