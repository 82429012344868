import React, { useState, useEffect } from "react";
import moment from "moment";
import clsx from "clsx";
import { IconButton, Icon, Grid, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";

import Table from "~/components/Table";
import Loader from "~/components/Loader";
import ViewTicketDetails from "~/modules/ViewTicketDetails";

import {
	fetchBorletteTicket,
	deleteBorletteTicket,
} from "~/redux/helpers/borlette";

export default () => {
	const [pendingTickets, setPendingTickets] = useState(true);
	const [pendingDeleteTicket, setPendingDeleteTicket] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTicket, setSelectedTicket] = useState({});
	const [borletteTickets, setBorletteTickets] = useState({});
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [count, setCount] = useState(0);
	const columns = [
		{ id: "purchasedOn", label: "Purchased On", minWidth: 100 },
		{ id: "_id", label: "Ticket ID", minWidth: 170 },
		{ id: "totalAmountPlayed", label: "Amount Paid", minWidth: 170 },
		{ id: "totalAmountWon", label: "Rewards", minWidth: 100 },
		{ id: "isCashout", label: "Cashout", minWidth: 100 },
		{ id: "status", label: "Status", minWidth: 100 },
		{ id: "action", label: "Action", minWidth: 100 },
	];

	const getBorletteTickets = (limit, offset) => {
		setPendingTickets(true);
		fetchBorletteTicket(null, limit, offset)
			.then((response) => {
				const { borletteTicketList, count } = response;
				setBorletteTickets(
					borletteTicketList.map((ticket) => ({
						...ticket,
						totalAmountPlayed: ticket.totalAmountPlayed / 100,
						totalAmountWon: (ticket.totalAmountWon || 0) / 100,
						isCashout: ticket.isAmountDisbursed ? "Yes" : "No",
						numbers: ticket.numbers.map(
							({ numberPlayed, amountPlayed, amountWon }) => ({
								numberPlayed,
								amountPlayed: amountPlayed / 100,
								amountWon: (amountWon || 0) / 100,
							})
						),
					}))
				);
				setCount(count);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPendingTickets(false);
			});
	};

	const handleTicketClick = (ticket) => {
		setSelectedTicket(ticket);
		setIsOpen(true);
	};

	const processDeleteTicket = (ticket) => {
		setPendingDeleteTicket(ticket._id);
		deleteBorletteTicket(ticket._id)
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				setBorletteTickets(
					borletteTickets.filter((item) => item._id !== ticket._id)
				);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setPendingDeleteTicket(false);
			});
	};

	const renderAction = (ticket) => {
		return (
			<>
				<IconButton
					color="primary"
					aria-label="view ticket numbers"
					component="span"
					onClick={() => handleTicketClick(ticket)}
				>
					<Icon
						className={clsx("fa", "fa-eye")}
					/>
				</IconButton>
				{ticket.status === "ACTIVE" &&
					(pendingDeleteTicket === ticket._id ? (
						<CircularProgress size={15} />
					) : (
						<IconButton
							color="secondary"
							aria-label="delete ticket"
							component="span"
							onClick={() => processDeleteTicket(ticket)}
						>
							<Icon
								className={clsx("fa", "fa-trash")}
							/>
						</IconButton>
					))}
			</>
		);
	};

	const handleChangePage = (e, page) => {
		setPage(page + 1);
	};

	useEffect(() => {
		const limit = rowsPerPage;
		const offset = (page - 1) * rowsPerPage;
		getBorletteTickets(limit, offset);
	}, [rowsPerPage, page]);

	const rows = borletteTickets.length
		? borletteTickets.map((ticket) => ({
				...ticket,
				isCashout: ticket.isAmountDisbursed ? "Yes" : "No",
				purchasedOn: moment(ticket.createdAt).format("DD MMM, YYYY HH:mm A"),
				totalAmountWon: ticket.totalAmountWon || 0,
				action: renderAction(ticket),
		  }))
		: [];
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Table
						pending={pendingTickets}
						columns={columns}
						rows={rows}
						count={count}
						pagination={true}
						rowsPerPage={rowsPerPage}
						page={page - 1}
						handleChangePage={handleChangePage}
					/>
				</Grid>
			</Grid>
			<ViewTicketDetails
				selectedTicket={selectedTicket}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};
