import { makeStyles } from "@material-ui/core/styles";
import LoginBackground from "~/assets/images/login-background.jpeg";

export default makeStyles((theme) => ({
	container: {
		// background: theme.palette.background.header,
	},
	lotteryBackground: {
		backgroundImage: `url(${LoginBackground})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		// backgroundPosition: "center",
		height: "100vh",
	},
	loginBoxContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
		padding: "0rem 1rem",
		[theme.breakpoints.up("md")]: {
			padding: "0rem 5rem",
		},
		[theme.breakpoints.up("sm")]: {
			padding: "0rem 7rem",
		},
	},
	loginBox: {
		background: theme.palette.background.header,
		padding: "1rem 2rem",
	},
}));
