import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
	Grid,
	Typography,
	Box,
} from "@material-ui/core";
import Header from "~/modules/Header";
import PurchaseLotteryTicket from "~/modules/PurchaseLotteryTicket";

import { fetchBorlette } from "~/redux/helpers/borlette";

import useStyles from "./styles";

export default function Dashboard(props) {
	const classes = useStyles();
	const history = useHistory();
	const { lotteryId } = useParams();
	const [countDown, setCountDown] = useState(-1);
	const [borlette, setBorlette] = useState({});
	const [pending, setPending] = useState(true);

	useEffect(() => {
		fetchBorlette(lotteryId)
			.then((response) => {
				if (response.countDown < 10000) {
					history.push(`/dashboard`);
				}
				setBorlette(response.borlette);
				setCountDown(response.countDown);					
			})
			.catch((error) => console.log(error))
			.finally(() => setPending(false));
	}, []);
	const data = [
		{
			title: "Sales",
			value: "$ 568,000",
			icon: "fa-bar-chart",
		},
		{
			title: "Awards",
			value: "$ 260,000",
			icon: "fa-trophy",
		},
		{
			title: "Commission",
			value: "$ 85,200",
			icon: "fa-scissors",
		},
		{
			title: "Balance",
			value: "$ 222,800",
			icon: "fa-bank",
		},
	];
	return (
		<>
			<Header />
			<Grid container justify="center" spacing={5}>
				<Grid item xs={10} sm={6} md={4}>
					<Box className={classes.pageTitleWrapper} py={2}>
						<Typography variant="h1">Purchase Ticket</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container justify="center">
				<Grid item xs={10} sm={6} md={5}>
					<PurchaseLotteryTicket
						pending={pending}
						borlette={borlette}
						countDown={countDown}
					/>
				</Grid>
			</Grid>
		</>
	);
}
