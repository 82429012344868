import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	accordion: {
		display: "flex",
		flexDirection: "column",
	},
	accordionHeader: {
		// backgroundColor: "#eee",
		color: "#444",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		border: "none",
		outline: "none",
	},
	accordionContent: {
		backgroundColor: "white",
		overflow: "hidden",
		transition: "max-height 0.3s ease",
	},
	rotate: {
		transform: "rotate(90deg)",
	},
}));

export default function Accordion(props) {
	const classes = useStyles();
	const { initialExpanded, header, content } = props;
	const [active, setActive] = useState(initialExpanded || false);
	const [panelHeight, setPanelHeight] = useState(0);;

	const contentEl = useRef(null);;

	useEffect(() => {
		setPanelHeight(active ? contentEl.current.scrollHeight : 0);
	}, [active]);;

	const toggleAccordion = () => {
		setActive(!active);;
	};;

	return (
		<div className={classes.accordion}>
			<div
				className={`${classes.accordionHeader}`}
				onClick={toggleAccordion}
			>
				{header()}
			</div>
			<div
				ref={contentEl}
				style={{ maxHeight: `${panelHeight}px` }}
				className={classes.accordionContent}
			>
				{content()}
			</div>
		</div>
	);
}