import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Table from "~/components/Table";

import { fetchBorletteList } from "~/redux/helpers/borlette";

export default () => {
	const history = useHistory();
	const [pending, setPending] = useState(true);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [borletteList, setBorletteList] = useState([]);
	const [count, setCount] = useState(0);

	const columns = [
		{ id: "id", label: "ID" },
		{ id: "name", label: "Name" },
		{ id: "scheduledTime", label: "Scheduled Time" },
		{ id: "status", label: "Status" },
	];

	const rows = borletteList.map((item) => ({
		id: item._id,
		name: `${item.name}`,
		phone: item.phoneNumber,
		scheduledTime: moment(item.schedule).format("DD MMM, YYYY hh:mm A"),
		status: item.status,
	}));

	const handleChangePage = (e, page) => {
		setPage(page + 1);
	};

	useEffect(() => {
		setPending(true);
		const limit = rowsPerPage;
		const offset = (page - 1) * rowsPerPage;
		fetchBorletteList({
			limit,
			offset,
		})
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				const { borletteList, count } = response;
				setBorletteList(borletteList);
				setCount(count);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
			});
	}, [page]);
	return (
		<Table
			pending={pending}
			columns={columns}
			rows={rows}
			onRowClick={(item) => history.push(`/lottery/${item.id}`)}
			count={count}
			pagination={true}
			rowsPerPage={rowsPerPage}
			page={page - 1}
			handleChangePage={handleChangePage}
		/>
	);
};
