import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const fetchTransactions = async ({
	startDate,
	endDate,
	limit,
	offset,
	transactionName,
}) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				startDate,
				endDate,
				limit: 10,
				offset,
				transactionName,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const fetchBorletteTransactionSummary = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/summary/borlette/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};