import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import TicketList from "~/modules/TicketList";

import useStyles from "./styles";

export default (props) => {
	const classes = useStyles();
	return (
		<>
			<Header />
			<PageTitle
				title="Borlette Tickets"
				activeTabIndex={0}
				onTabChange={() => null}
				showAction={false}
			/>
			<Box className={classes.contentWrapper}>
				<TicketList />
			</Box>
		</>
	);
}
