import axios from "axios";
import { getAccessToken } from "~/redux/helpers";
import config from "~/config";

export const getSigningKey = async (fileName, contentType, acl) => {
	try {
		const accessToken = await getAccessToken();
		if (!accessToken) {
			throw "Unauthorized";
		}
		const response = await axios({
			url: `${
				config.apiBase
			}/upload/signing_key?fileName=${fileName}&contentType=${contentType}${
				acl ? `&acl=${acl}` : ``
			}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return response.data;
	} catch (error) {
		console.log("getSigningKey error => ");
		console.log(error);
		return {
			error,
		};
	}
};

export const uploadBase64ImageToS3 = async (base64, contentType, acl) => {
	try {
		const accessToken = await getAccessToken();
		if (!accessToken) {
			throw "Unauthorized";
		}
		const response = await axios({
			url: `${
				config.apiBase
			}/upload`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify({
				base64, contentType, acl
			}),
		});
		return response.data;
	} catch (error) {
		console.log(error);
		return {
			error,
		};
	}
};
