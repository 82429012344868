import React, { useState, useEffect } from "react";
import {
	Route,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
	useParams,
	matchPath,
} from "react-router-dom";
import clsx from "clsx";
import { Grid, Box, Typography, Paper } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import BorletteOverview from "~/modules/BorletteOverview";
import BorletteTickets from "~/modules/BorletteTickets";

import { fetchBorlette, fetchBorletteTicket } from "~/redux/helpers/borlette";

import useStyles from "./styles";

export default function LotteryDetails(props) {
	const classes = useStyles();
	const { borletteId } = useParams();

	const [pending, setPending] = useState(true);
	const [pendingTickets, setPendingTickets] = useState(true);
	const [borletteDetails, setBorletteDetails] = useState({});
	const [borletteTickets, setBorletteTickets] = useState({});
	const [countDown, setCountDown] = useState(-1);

	const getBorletteDetails = (borletteId) => {
		return fetchBorlette(borletteId)
			.then((response) => {
				setBorletteDetails(response.borlette);
				setCountDown(response.countDown);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
			});
	};

	const getBorletteTickets = (borletteId) => {
		fetchBorletteTicket(borletteId)
			.then((response) => {
				setBorletteTickets(response.borletteTicketList);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPendingTickets(false);
			});
	};

	useEffect(() => {
		getBorletteDetails(borletteId).then(() =>
			getBorletteTickets(borletteId)
		);
	}, [borletteId]);

	return (
		<>
			<Header />
			<PageTitle title="Borlette Details" showAction={false} />
			<Box className={classes.contentWrapper}>
				<BorletteOverview
					borletteDetails={borletteDetails}
					countDown={countDown}
					pending={pending}
				/>
				<Box className={classes.sectionHeader}>
					<Typography variant="h1">Borlette Tickets</Typography>
				</Box>
				<BorletteTickets
					pending={pendingTickets}
					countDown={countDown}
					borletteDetails={borletteDetails}
					borletteTickets={borletteTickets}
				/>
			</Box>
		</>
	);
}
