import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	contentWrapper: {
		padding: "0.5rem",
	},
	sectionHeader: {
		padding: "1rem 0rem",
	},
}));
