import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Paper,
	Box,
	Typography,
	Icon,
} from "@material-ui/core";
import Button from "~/components/Forms/Button";

const useStyles = makeStyles((theme) => ({
	cardHeader: {
		position: "relative",
		display: "flex",
		background: theme.palette.background.itemHeader,
		color: theme.palette.primary.main,
		alignItems: "center",
		gap: "0.75rem",
		padding: "1rem 0.5rem",
	},
	fullHeight: {
		height: "100%",
	},
	headerIcon: {
		fontSize: "1.5rem",
		width: "1.25em",
	},
	fixPositioned: {
		position: "absolute",
		right: "1rem",
		cursor: "pointer",
	},
}));

export default (props) => {
	const { title, fontIcon, children, actionTitle, actionIcon, onAction, ...otherProps } = props;
	const classes = useStyles();
	return (
		<Paper {...otherProps}>
			<Box className={classes.cardHeader}>
				<Icon
					className={clsx("fa fa-2x", fontIcon, classes.headerIcon)}
				/>
				<Typography variant="h5">{title}</Typography>
				{onAction &&
					(actionTitle ? (
						<Button
							className={classes.fixPositioned}
							onClick={onAction}
							color="primary"
							variant="contained"
						>
							{actionIcon ? (
								<Icon
									onClick={onAction}
									className={clsx(
										"fa",
										actionIcon,
										classes.fixPositioned
									)}
								/>
							) : null}
							{actionTitle}
						</Button>
					) : (
						<Icon
							onClick={onAction}
							className={clsx(
								"fa",
								actionIcon,
								classes.fixPositioned
							)}
						/>
					))}
			</Box>
			{children}
		</Paper>
	);
}