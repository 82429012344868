import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
	Box,
	Typography,
	Grid
} from "@material-ui/core";
import moment from "moment";
import Card from "~/components/Card";
import Loader from "~/components/Loader";
import DateCountdown from "~/components/DateCountdown";

import {
	fetchBorletteTransactionSummary
} from "~/redux/helpers/transaction";

import useStyles from "./styles";

const getRandomAmount = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min) * 100;
};

export default ({ borletteDetails, countDown, pending }) => {
	const classes = useStyles();
	const { borletteId } = useParams();
	const [borletteCountDown, setBorletteCountDown] = useState(countDown);
	const [pendingSalesSummary, setPendingSalesSummary] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [salesSummary, setSalesSummary] = useState({
		totalSales: 0,
		totalCommissions: 0,
		totalRewards: 0,
		totalAmountPaid: 0,
		totalAmountReceived: 0,
		totalBalance: 0,
	});

	const getBorletteSalesSummary = () => {
		setPendingSalesSummary(true);
		fetchBorletteTransactionSummary(borletteId)
			.then((response) => {
				const transactions = [];
				const salesSummary = {
					totalSales: 0,
					totalCommissions: 0,
					totalMegapayCommissions: 0,
					totalRewards: 0,
					totalAmountPaid: 0,
					totalAmountReceived: 0,
					totalPendingBalanceAgent: 0,
					totalPendingBalanceMegapay: 0,
					totalProfit: 0,
				};
				for (let agentId in response.transactionSummary) {
					const {
						agent,
						commissions,
						megapayCommissions,
						paymentMadeToAgent,
						paymentMadeToMegapay,
						paymentReceivedFromAgent,
						rewards,
						sales,
					} = response.transactionSummary[agentId];
					const transactionItem = {
						agent,
						pending: false,
						agentId: parseInt(agentId),
						commissions: commissions / 100,
						megapayCommissions: megapayCommissions / 100,
						paymentMadeToAgent: paymentMadeToAgent / 100,
						paymentMadeToMegapay: paymentMadeToMegapay / 100,
						paymentReceivedFromAgent:
							paymentReceivedFromAgent / 100,
						rewards: rewards / 100,
						sales: sales / 100,
						amountToPay:
							(commissions + rewards - paymentMadeToAgent) / 100,
						profit:
							(sales -
								commissions -
								megapayCommissions -
								rewards) /
							100,
						pendingBalanceAgent:
							(sales -
								commissions -
								rewards -
								paymentReceivedFromAgent +
								paymentMadeToAgent) /
							100,
						pendingBalanceMegapay:
							paymentMadeToMegapay - megapayCommissions / 100,
					};
					salesSummary.totalSales += transactionItem.sales;
					salesSummary.totalCommissions +=
						transactionItem.commissions;
					salesSummary.totalMegapayCommissions +=
						transactionItem.megapayCommissions;
					salesSummary.totalRewards += transactionItem.rewards;
					salesSummary.totalAmountPaid +=
						transactionItem.paymentMadeToAgent;
					salesSummary.totalAmountReceived +=
						transactionItem.paymentReceivedFromAgent;
					salesSummary.totalPendingBalanceAgent +=
						transactionItem.pendingBalanceAgent;
					salesSummary.totalPendingBalanceMegapay +=
						transactionItem.pendingBalanceMegapay;
					salesSummary.totalProfit += transactionItem.profit;
					transactions.push(transactionItem);
				}
				setTransactions(transactions);
				setSalesSummary(salesSummary);
			})
			.catch((error) => console.log(error))
			.finally(() => setPendingSalesSummary(false));
	};

	useEffect(() => {
		getBorletteSalesSummary();
	}, []);

	if (pending) {
		return <Loader />
	}

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<Card title="Lottery Information" fontIcon="fa-info-circle">
						<Box className={classes.container}>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">ID:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteId}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">Type:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										Borlette
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">Name:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteDetails.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Scheduled On:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{moment(
											borletteDetails.schedule
										).format("MMM DD, YYYY hh:mm A")}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Created On:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{moment(
											borletteDetails.createdAt
										).format("MMM DD, YYYY")}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Status:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteDetails.status}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card
						title="Lottery Results"
						fontIcon="fa-money"
						className={classes.fullHeight}
					>
						<Box className={classes.resultContainer}>
							{borletteDetails.status === "SCHEDULED" ? (
								borletteCountDown >= 0 ? (
									<Grid
										container
										justify="center"
										spacing={3}
									>
										<Grid item>
											<DateCountdown
												mostSignificantFigure="day"
												countDown={borletteCountDown}
												callback={() =>
													setBorletteCountDown(-1)
												}
											/>
										</Grid>
									</Grid>
								) : (
									<Box
										className={classes.resultBallContainer}
									>
										<Typography
											variant="h2"
											className={classes.noData}
										>
											Waiting for results.
										</Typography>
									</Box>
								)
							) : (
								<Grid
									container
									spacing={3}
									justify="center"
									alignItems="center"
								>
									{borletteDetails.results.map(
										(result, index) => (
											<Grid
												key={`result-item-${index}`}
												item
												xs={4}
												className={
													classes.resultBallContainer
												}
											>
												<Box
													className={
														classes.resultBall
													}
												>
													<Typography variant="h5">
														{result}
													</Typography>
												</Box>
											</Grid>
										)
									)}
								</Grid>
							)}
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card
						title="Amount Details"
						fontIcon="fa-money"
						className={classes.fullHeight}
					>
						<Box className={classes.container}>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Total Sales:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalSales}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Agent Commission:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalCommissions}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Total Rewards:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalRewards}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={3}
								className={classes.borderTop}
							>
								<Grid item xs={8}>
									<Typography variant="h4">
										Pending Amount to Receive:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalCommissions +
											salesSummary.totalRewards -
											salesSummary.totalAmountPaid}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Pending Amount to Pay:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalSales -
											salesSummary.totalAmountReceived}
									</Typography>
								</Grid>
							</Grid>
						</Box>{" "}
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
