import React, { useState, useEffect } from "react";
import moment from "moment";
import { MenuItem, Grid, Box, Typography } from "@material-ui/core";

import DaterangePicker from "~/components/DaterangePicker";
import Card from "~/components/Card";
import Loader from "~/components/Loader";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";
import Table from "~/components/Table";

import { fetchTransactions } from "~/redux/helpers/transaction";

import useStyles from "./styles";

export default () => {
	const classes = useStyles();
	const [page, setPage] = useState(1);
	const [transactionSummary, setTransactionSummary] = useState({
		BORLETTE_SALE: 0,
		BORLETTE_REWARD: 0,
		BORLETTE_COMMISSION: 0,
		BORLETTE_PAYMENT_RECEIVED: 0,
		BORLETTE_PAYMENT_MADE: 0,
		BORLETTE_MEGAPAY_COMMISSION: 0,
	});
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [pending, setPending] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [filter, setFilter] = useState({
		transactionName: "ALL",
	});
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf("day").valueOf(),
		endDate: moment().endOf("day").valueOf(),
	});
	const columns = [
		{ id: "date", label: "Date", minWidth: 100 },
		{
			id: "transactionDescription",
			label: "Transaction Description",
			minWidth: 170,
		},
		// { id: "transactionName", label: "Transaction Type", minWidth: 100 },
		{ id: "transactionAmount", label: "Amount", minWidth: 100 },
		{ id: "status", label: "Status", minWidth: 100 },
	];

	const handleDateRangeChange = (dateRange) => {
		setDateRange({
			startDate: dateRange[0],
			endDate: dateRange[1],
		});
	};

	const getTransactionDescription = (
		transactionName,
		transactionAmount,
		user,
		ticketId
	) => {
		switch (transactionName) {
			case "BORLETTE_SALE":
				return `Agent "${user}" made a sale of ${transactionAmount}`;
			case "BORLETTE_COMMISSION":
				return `Agent "${user}" received a commission of ${transactionAmount}`;
			case "BORLETTE_REWARD":
				return `Reward against ticket ID ${ticketId}`;
			case "BORLETTE_PAYMENT_RECEIVED":
				return `Recevied payment of ${transactionAmount} from agent "${user}"`;
			case "BORLETTE_PAYMENT_MADE":
				return `Made payment of ${transactionAmount} to agent "${user}"`;
			case "BORLETTE_MEGAPAY_COMMISSION":
				return `Commission of ${transactionAmount} deducted for MegaPay`;
			default:
				return "";
		}
	};

	const getTransactionSummaryName = (transactionName) => {
		switch (transactionName) {
			case "BORLETTE_SALE":
				return `Sale`;
			case "BORLETTE_COMMISSION":
				return `Commission`;
			case "BORLETTE_PAYMENT_RECEIVED":
				return `Payment Recevied`;
			case "BORLETTE_PAYMENT_MADE":
				return `Payment Made`;
			case "BORLETTE_MEGAPAY_COMMISSION":
				return `Megapay Commission`;
			case "BORLETTE_REWARD":
				return `Rewards`;
			default:
				return "";
		}
	};

	const rows = transactions
		.map((transaction) => ({
			...transaction,
			transactionAmount: transaction.transactionAmount / 100,
			date: moment(transaction.createdAt).format("DD MMM, YYYY"),
			transactionDescription: getTransactionDescription(
				transaction.transactionName,
				transaction.transactionAmount / 100,
				transaction.name,
				transaction.serviceReference
			),
		}))
		.filter((transaction) =>
			Boolean(filter.transactionName) && filter.transactionName !== "ALL"
				? transaction.transactionName === filter.transactionName
				: true
		);

	const handleChangePage = (e, page) => {
		setPage(page + 1);
	};

	useEffect(() => {
		setIsLoading(true);
		const limit = rowsPerPage;
		const offset = (page - 1) * rowsPerPage;
		fetchTransactions({
			...dateRange,
			...filter,
			limit,
			offset,
		})
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				setTransactions(response.transactions);
				const responseSummary = {};
				let balance = 0;
				response.summary.map((item) => {
					responseSummary[item._id] = item.amount;
				});
				setTransactionSummary(
					Object.assign(
						{
							BORLETTE_SALE: 0,
							BORLETTE_REWARD: 0,
							BORLETTE_COMMISSION: 0,
							BORLETTE_PAYMENT_RECEIVED: 0,
							BORLETTE_PAYMENT_MADE: 0,
							BORLETTE_MEGAPAY_COMMISSION: 0,
						},
						responseSummary
					)
				);
				setCount(response.count);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
				setIsLoading(false);
			});
	}, [page, filter, dateRange]);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Card
							title="Filter Transaction"
							fontIcon="fa-info-circle"
						>
							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											select
											type="select"
											label="Transaction Type"
											value={filter.transactionName}
											onChange={(e) =>
												setFilter({
													...filter,
													transactionName:
														e.target.value,
												})
											}
										>
											<MenuItem value={"ALL"}>
												All Transaction
											</MenuItem>
											<MenuItem value={"BORLETTE_SALE"}>
												Borlette Ticket Sale
											</MenuItem>
											<MenuItem
												value={"BORLETTE_COMMISSION"}
											>
												Borlette Ticket Commission
											</MenuItem>
											<MenuItem
												value={"BORLETTE_REWARDS"}
											>
												Borlette Rewards
											</MenuItem>
											<MenuItem
												value={"BORLETTE_PAYMENT_MADE"}
											>
												Payment Made to Agent
											</MenuItem>
											<MenuItem
												value={
													"BORLETTE_PAYMENT_RECEIVED"
												}
											>
												Payment Received from Agent
											</MenuItem>
										</TextField>
									</Grid>
									<Grid item xs={12}>
										<DaterangePicker
											startDate={dateRange.startDate}
											endDate={dateRange.endDate}
											onChange={handleDateRangeChange}
										/>
									</Grid>
								</Grid>
							</Box>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card
							title="Transaction Summary"
							fontIcon="fa-info-circle"
						>
							<Box p={2}>
								{isLoading ? (
									<Loader />
								) : (
									<>
										{Object.keys(transactionSummary).map(
											(key) =>
												[
													"BORLETTE_COMMISSION",
													"BORLETTE_REWARD",
													"BORLETTE_SALE",
												].includes(key) ? (
													<Grid container spacing={2}>
														<Grid item xs={6}>
															<Typography variant="h5">
																{getTransactionSummaryName(
																	key
																)}
															</Typography>
														</Grid>
														<Grid item xs={6}>
															<Typography variant="body1">
																{transactionSummary[
																	key
																] / 100}
															</Typography>
														</Grid>
													</Grid>
												) : null
										)}
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Franchise Payment:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{((transactionSummary[
														"BORLETTE_SALE"
													] -
														transactionSummary[
															"BORLETTE_COMMISSION"
														] -
														transactionSummary[
															"BORLETTE_REWARD"
														]) || 0) /
															100}
												</Typography>
											</Grid>
										</Grid>
									</>
								)}
							</Box>
						</Card>
					</Grid>
				</Grid>
				<Grid container spacing={2}></Grid>
			</Grid>
			<Grid item xs={12}>
				<Table
					pending={isLoading}
					stickyHeader={false}
					columns={columns}
					rows={rows}
					count={count}
					pagination={true}
					rowsPerPage={rowsPerPage}
					page={page - 1}
					handleChangePage={handleChangePage}
				/>
			</Grid>
		</Grid>
	);
};
