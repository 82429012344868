import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	CircularProgress,
	Typography,
	Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	centeredData: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export default function () {
	const classes = useStyles();
	return (
		<Box className={classes.centeredData} py={3}>
			<CircularProgress />
		</Box>
	);
}
