import React, { useState } from "react";
import clsx from "clsx";
import { Box, Grid, MenuItem, Typography, Icon } from "@material-ui/core";

import Table from "~/components/Table";
import SidePanel from "~/components/SidePanel";
import Button from "~/components/Forms/Button";
import NoData from "~/components/NoData";
import TextField from "~/components/Forms/TextField";

import useStyles from "./styles";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
const years = [];
for (let i = 2010; i > 1970; i--) {
	years.push(i);
}
const getRandomAmount = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min);
};

export default function ({ isOpen, onClose, selectedTicket }) {
	const classes = useStyles();

	const columns = [
		{ id: "numberPlayed", label: "Number" },
		{ id: "amountPlayed", label: "Amount" },
		{ id: "amountWon", label: "Rewards" },
	];
	const rows = (selectedTicket.numbers || []).map((item) => ({
		amountPlayed: item.amountPlayed,
		amountWon: item.amountWon || 0,
		numberPlayed: item.numberPlayed,
	}));

	const renderTotal = () => (
		<Box className={classes.tableFooter}>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="h4">Total Amount Paid</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h4">
						{selectedTicket.totalAmountPlayed}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="h4">Total Rewards</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h4">
						{selectedTicket.totalAmountWon || 0}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
	return (
		<SidePanel
			title={"Ticket Details"}
			isOpen={isOpen}
			onClose={onClose}
			onAction={(e) => null}
		>
			<Box className={classes.contentContainer}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Table
							columns={columns}
							rows={rows}
							footer={renderTotal()}
						/>
					</Grid>
				</Grid>
			</Box>
		</SidePanel>
	);
}
