import { combineReducers } from "redux";
import appConfig from "./appConfig";
import user from "./user";

const reducer = combineReducers({
	appConfig,
	user,
});

export default reducer;
