import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	root: {
		width: "100%",
	},
	container: {
		maxHeight: 440,
	},
	filterButton: {
		padding: "1rem 1.75rem",
		borderRadius: "0.25rem",
	},
});
