import React, { useState, useEffect } from "react";
import moment from "moment";
import { MenuItem, Grid, Box, Typography } from "@material-ui/core";

import DaterangePicker from "~/components/DaterangePicker";
import Card from "~/components/Card";
import Loader from "~/components/Loader";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";
import Table from "~/components/Table";

import { cashoutBorletteTicket, fetchTicket } from "~/redux/helpers/borlette";

import useStyles from "./styles";

export default () => {
	const classes = useStyles();
	const [borletteTicket, setBorletteTicket] = useState({});
	const [ticketId, setTicketId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [pending, setPending] = useState(false);
	const [cashoutPending, setCashoutPending] = useState(false);

	const fetchTicketDetails = () => {
		setIsLoading(true);
		setPending(true);
		fetchTicket(ticketId)
			.then((response) => {
				if (!response.error) {
					setBorletteTicket(response.borletteTicket);
				}
			})
			.finally(() => {
				setPending(false);
				setIsLoading(false);
			});
	};

	const processCashout = () => {
		setCashoutPending(true);
		cashoutBorletteTicket(borletteTicket._id)
			.then((response) => {
				if (!response.error) {
					setBorletteTicket(response.borletteTicket);
				}
			})
			.finally(() => {
				setCashoutPending(false);
			});
	};

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Card title="Enter Ticket ID" fontIcon="fa-info-circle">
							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											type="text"
											label="Ticket ID"
											value={ticketId}
											onChange={(e) =>
												setTicketId(e.target.value)
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											onClick={fetchTicketDetails}
											pending={pending}
											color="primary"
											variant="contained"
											fullWidth
										>
											Proceed
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card title="Ticket Details" fontIcon="fa-info-circle">
							<Box p={2}>
								{isLoading ? (
									<Loader />
								) : borletteTicket._id ? (
									<>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Ticket ID:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{borletteTicket._id}
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Purchased On:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{moment(
														borletteTicket.createdAt
													).format("DD MMM, YYYY")}
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Amount Played:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{borletteTicket.totalAmountPlayed /
														100}
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Amount Won:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{borletteTicket.totalAmountWon /
														100}
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Status:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{borletteTicket.status}
												</Typography>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Typography variant="h5">
													Cashout:
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant="body1">
													{borletteTicket.isAmountDisbursed
														? "Yes"
														: "No"}
												</Typography>
											</Grid>
										</Grid>
										{!borletteTicket.isAmountDisbursed &&
										borletteTicket.status ===
											"COMPLETED" ? (
											<Box p={2}>
												<Button
													onClick={processCashout}
													pending={cashoutPending}
													color="primary"
													variant="contained"
													fullWidth
												>
													Cashout Ticket
												</Button>
											</Box>
										) : null}
									</>
								) : null}
							</Box>
						</Card>
					</Grid>
				</Grid>
				<Grid container spacing={2}></Grid>
			</Grid>
		</Grid>
	);
};
